var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_course_block"},[_c('div',{staticClass:"about_course_header_block"},[_c('div',{staticClass:"about_course_header_container"},[_c('div',{staticClass:"about_course_header_label"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")])])]),_c('div',{staticClass:"about_course_main_block"},[_c('div',{staticClass:"about_course_main_container"},[_c('div',{staticClass:"about_course_content_container"},[(!_vm.course)?_c('div',{staticClass:"about_course_plot m-0"},[_c('el-skeleton',{attrs:{"rows":6}})],1):_c('div',{staticClass:"about_course_plot m-0"},[_c('a',{staticClass:"about_course_plot_about"},[_vm._v(" "+_vm._s(_vm.$t('About_course'))+" ")]),_vm._l((_vm.course.modules),function(module){return _c('router-link',{staticClass:"about_course_plot_module",attrs:{"to":{ name: 'course-module', params: { slug: _vm.course.slug, module: module.id } }}},[_vm._v(" "+_vm._s(module.title)+" ")])}),(_vm.course.quiz)?_c('router-link',{staticClass:"about_course_plot_module",attrs:{"to":{ name: 'final-testing', params: { slug: _vm.course.slug }}}},[_vm._v(" "+_vm._s(_vm.$t('Final_testing'))+" ")]):_vm._e()],2)]),_c('div',{staticClass:"about_course_desc"},[_vm._m(0),_c('div',{staticClass:"about_course_desc_text_container"},[_c('div',{staticClass:"about_course_desc_title"},[_vm._v(" "+_vm._s(_vm.course.title)+" ")]),_c('div',{staticClass:"about_course_desc_label"},[_vm._v(" "+_vm._s(_vm.$t('About_course'))+" ")]),_c('div',{staticClass:"about_course_desc_text",domProps:{"innerHTML":_vm._s(_vm.course.detail)}}),_c('router-link',{staticClass:"about_course_desc_next_btn",attrs:{"to":{
            name: 'course-module',
            params: {
              slug: _vm.course.slug,
              module: _vm.currentModuleId
            }
          }}},[_vm._v(" "+_vm._s(_vm.$t('Further'))+" ")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about_course_desc_img_container"},[_c('div',{staticClass:"about_course_desc_img"})])
}]

export { render, staticRenderFns }