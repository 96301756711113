<template>
  <div class="about_course_block">
    <div class="about_course_header_block">
      <div class="about_course_header_container">
        <div class="about_course_header_label">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div class="about_course_main_block">
      <div class="about_course_main_container">
        <div class="about_course_content_container">
          <div class="about_course_plot m-0" v-if="!course">
            <el-skeleton :rows="6" />
          </div>
          <div class="about_course_plot m-0" v-else>
            <a class="about_course_plot_about">
              {{ $t('About_course') }}
            </a>
            <router-link
                v-for="module in course.modules"
                :to="{ name: 'course-module', params: { slug: course.slug, module: module.id } }"
                class="about_course_plot_module">
              {{ module.title }}
            </router-link>
            <router-link v-if="course.quiz" :to="{ name: 'final-testing', params: { slug: course.slug }}" class="about_course_plot_module">
              {{ $t('Final_testing') }}
            </router-link>
          </div>
        </div>
        <div class="about_course_desc">
          <div class="about_course_desc_img_container">
            <div class="about_course_desc_img"></div>
          </div>
          <div class="about_course_desc_text_container">
            <div class="about_course_desc_title">
              {{ course.title }}
            </div>
            <div class="about_course_desc_label">
              {{ $t('About_course') }}
            </div>
            <div class="about_course_desc_text" v-html="course.detail"></div>
            <router-link class="about_course_desc_next_btn" :to="{
              name: 'course-module',
              params: {
                slug: course.slug,
                module: currentModuleId
              }
            }">
              {{ $t('Further') }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  name: 'AboutCourse',
  data() {
    return {
      course: false,
    }
  },
  methods: {
    getCourseDetails() {
      let apiRoot = API_ROOT
      let slug = this.$route.params.slug
      this.$http.get(`${apiRoot}/api/course/${slug}`)
          .then(res => {
            this.course = res.body
          })
          .catch(err => {
            if (err.status === 404) {
              this.$router.push({ name: 'not-found' })
            }
            console.log(err)
          })
          .finally(() => {

          })
    },
  },
  mounted() {
    this.getCourseDetails()
  },
  beforeCreate() {
    let apiRoot = API_ROOT
    let slug = this.$route.params.slug
    // this.$http.post(`${apiRoot}/api/course/enroll/${slug}`)
    //     .then(res => {
    //       if (res.data) {
    //         Vue.toastr({
    //           message: 'Success',
    //           description: 'Вы успешно записались на курс',
    //           type: 'success'
    //         })
    //       }
    //     })
    //     .catch(err => console.log(err))
    //     .finally(() => {
    //
    //     })
  },
  computed: {
    currentModuleId: function () {
      if (!(this.course && this.course.modules))
        return;
      return this.course.modules.find(() => true).id
    }
  }
}
</script>

<style scoped>

</style>
